import {
  BW_COLOR_ACCENT,
  BW_COLOR_LITE_BLACK,
  deepMerge
} from 'src/libs/qb-brand-web-components'
import { faUserTag } from '@fortawesome/pro-light-svg-icons'

import defaultConfig from '../common/customConfig'
import { buildRedemptionUrl } from './util/brand.helpers'
import { BALANCES_SHOULD_FETCH_NFT_URL_PARAM } from '../../constants/api'

const customConfig = {
  myAccountLabel: 'brand:user.my-account',
  nftsIcon: faUserTag,
  nftIcon: faUserTag,
  nftReceivedTitle: 'brand:nft.thank-you-for-joining',
  howToEarnLabel: 'brand:reward.how-to-earn-points',
  signupButtonLabel: 'brand:common.create-account',
  howManyPointsYouEarnLabel: 'brand:reward.how-many-points-you-earn',
  buildRedeemWebsiteUrl: (url) => buildRedemptionUrl(url),
  balancesAPIParams: BALANCES_SHOULD_FETCH_NFT_URL_PARAM,
  currencyDelimiter: ',',
  cashbackDesc: 'brand:cashback.title',
  getCashbackLabel: 'brand:cashback.get-cashback',
  cashbackFromLabel: 'brand:cashback.cashback-from',
  footerColor: BW_COLOR_LITE_BLACK,
  dateFormatLabel: 'brand:common.date-time',
  iconColor: BW_COLOR_ACCENT,
  getPredefinedCashbackBrandsPath: '/get-predefined-stores'
}

export default deepMerge(defaultConfig, customConfig)
