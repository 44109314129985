import {
  CSS_CURSOR_POINTER,
  CSS_FULL_WIDTH
} from 'src/libs/qb-brand-web-components'
import React, { memo } from 'react'

import ShopItem, {
  ShopItemsPanel
} from '../../../../../components/shared/redeem/ShopItems'

const EarnRewardCards = ({ data, t, fullWidth, onSelectOption }) => {
  const isDataLengthOdd = data?.length % 2 !== 0
  return (
    <ShopItemsPanel fullWidth={fullWidth}>
      {data.map((option, index) => {
        const { label, description, image } = option
        return (
          <ShopItem
            key={index}
            label={t(`brand:earn_rewards.${label}.heading`)}
            description={description}
            fullColumn={isDataLengthOdd && index === 0}
            bgImage={image}
            onClick={() => onSelectOption(option)}
            bgImageOpacity={70}
            customCss={[CSS_CURSOR_POINTER, fullWidth && CSS_FULL_WIDTH]}
          />
        )
      })}
    </ShopItemsPanel>
  )
}

export default memo(EarnRewardCards)
